import { trpc } from '@/lib/trpc';
import { useMemo } from 'react';

export type MappedFlowStatus = 'active' | 'in-progress' | 'draft' | 'dead';

export type MappedFlow = {
  flow_config_id: number;
  name: string | null;
  subtitle?: string;
  status?: MappedFlowStatus;
  agent_type_id?: number | null;
  agent_category_ids?: number[];
  pinned?: boolean;
  extension_id?: number | null;
  is_global: boolean;
  type: string;
};

export default function useLoadFlows(client: string, agentCategoryId: number | undefined, agentTypeId: number | undefined, search: string | undefined) {
  const { data: globalFlows } = trpc.flows.configs.list.useQuery({ client: 'tools' });
  const { data: clientFlows } = trpc.flows.configs.list.useQuery({ client: client });
  const { data: extensions } = trpc.flows.extensions.list.useQuery({ client: client });
  const { data: pinnedFlows } = trpc.flows.actions.listPinned.useQuery({ client: client });

  const data = useMemo(() => {
    if (!globalFlows || !clientFlows) {
      return {
        pinned: [],
        client: [],
        global: [],
      };
    }

    // Status priority order for sorting
    const statusOrder = {
      active: 0,
      'in-progress': 1,
      draft: 2,
      dead: 3,
    };

    const sortByStatusAndName = (a: MappedFlow, b: MappedFlow) => {
      const statusCompare = (statusOrder[(a.status ?? 'draft') as MappedFlowStatus] ?? 999) - (statusOrder[(b.status ?? 'draft') as MappedFlowStatus] ?? 999);
      if (statusCompare !== 0) return statusCompare;

      return (a.name ?? '').localeCompare(b.name ?? '');
    };

    const allNormalMappedFlows = [...globalFlows, ...clientFlows]
      .map((flow) => ({
        flow_config_id: flow.id,
        name: flow.name,
        subtitle: undefined as string | undefined,
        status: flow.status,
        agent_type_id: flow.agent_type_id,
        agent_category_ids: flow.agent_category_ids,
        pinned: pinnedFlows?.includes(flow.id),
        extension_id: undefined,
        is_global: flow.client === 'tools',
        type: flow.flow_name,
      }))
      .filter((flow) => {
        let matches = true;

        if (search) {
          const searchLower = search.toLowerCase();
          const nameMatch = flow.name?.toLowerCase().includes(searchLower) ?? false;
          const subtitleMatch = flow.subtitle?.toLowerCase().includes(searchLower) ?? false;
          matches = matches && (nameMatch || subtitleMatch);
        }

        if (agentCategoryId) {
          matches = matches && (flow.agent_category_ids?.some((id) => id === agentCategoryId) ?? false);
        }

        if (agentTypeId) {
          matches = matches && flow.agent_type_id === agentTypeId;
        }

        return matches;
      });

    const mappedExtensions = extensions
      ?.map((ext) => {
        const globalFlow = globalFlows?.find((flow) => flow.id === ext.flow_config_id);
        return {
          ...ext,
          flow_config_id: ext.flow_config_id,
          name: ext.name,
          subtitle: globalFlow?.name,
          status: globalFlow?.status,
          agent_type_id: globalFlow?.agent_type_id,
          agent_category_ids: globalFlow?.agent_category_ids,
          pinned: pinnedFlows?.includes(ext.flow_config_id),
          extension_id: ext.id,
          is_global: false,
          type: globalFlow!.flow_name,
        };
      })
      .filter((flow) => {
        let matches = true;

        if (search) {
          const searchLower = search.toLowerCase();
          const nameMatch = flow.name?.toLowerCase().includes(searchLower) ?? false;
          const subtitleMatch = flow.subtitle?.toLowerCase().includes(searchLower) ?? false;
          matches = matches && (nameMatch || subtitleMatch);
        }

        if (agentCategoryId) {
          matches = matches && (flow.agent_category_ids?.some((id) => id === agentCategoryId) ?? false);
        }

        if (agentTypeId) {
          matches = matches && flow.agent_type_id === agentTypeId;
        }

        return matches;
      });

    const pinnedSorted = allNormalMappedFlows
      .filter((flow) => flow.pinned)
      .map((flow) => {
        // Replace with extension if it exists
        const extension = mappedExtensions?.find((ext) => ext.flow_config_id === flow.flow_config_id);
        return extension ?? flow;
      })
      .sort(sortByStatusAndName);

    const clientSorted = [...(mappedExtensions ?? []), ...allNormalMappedFlows.filter((flow) => clientFlows?.some((f) => f.id === flow.flow_config_id))].sort(
      sortByStatusAndName,
    );
    const globalSorted = allNormalMappedFlows.filter((flow) => globalFlows?.some((f) => f.id === flow.flow_config_id)).sort(sortByStatusAndName);

    return {
      pinned: pinnedSorted,
      client: clientSorted,
      global: globalSorted,
    };
  }, [globalFlows, clientFlows, extensions, pinnedFlows, agentCategoryId, agentTypeId, search]);

  return data;
}

import { Button } from '@/components/ui/button';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu';
import { FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '@/components/ui/menubar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Textarea } from '@/components/ui/textarea';
import { trpc } from '@/lib/trpc';
import { toTitleCase } from '@/lib/utils/frontend';
import { cn } from '@/lib/utils/twutils';
import { PenBox, Pencil, PlusCircle, Trash } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Balancer from 'react-wrap-balancer';
import { useAgents } from './Agents';
export default function TypesMenu(props: {
  selectedAgentCategoryId: number | undefined;
  selectedAgentTypeId: number | undefined;
  onAgentCategoryChange: (categoryId: number | undefined) => void;
  onAgentTypeChange: (typeId: number | undefined) => void;
  client: string;
}) {
  const router = useRouter();
  const { closeAgents } = useAgents();
  const { data: types } = trpc.flows.actions.listTypes.useQuery();
  const { data: categories } = trpc.flows.actions.listCategories.useQuery();
  const { mutate: addType } = trpc.flows.actions.addType.useMutation();
  const { mutate: addCategory } = trpc.flows.actions.addCategory.useMutation();
  const { mutate: deleteType } = trpc.flows.actions.deleteType.useMutation();
  const { mutate: deleteCategory } = trpc.flows.actions.deleteCategory.useMutation();
  const { mutate: updateType } = trpc.flows.actions.updateType.useMutation();
  const { mutate: updateCategory } = trpc.flows.actions.updateCategory.useMutation();

  const handleTypeClick = (typeId: number) => {
    if (props.selectedAgentTypeId === typeId) {
      props.onAgentTypeChange(undefined);
    } else {
      props.onAgentTypeChange(typeId);
      props.onAgentCategoryChange(undefined);
    }
  };

  const handleCategoryClick = (categoryId: number) => {
    if (props.selectedAgentCategoryId === categoryId) {
      props.onAgentCategoryChange(undefined);
    } else {
      props.onAgentCategoryChange(categoryId);
      props.onAgentTypeChange(undefined);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="h-1" />
      <div className="flex justify-between items-center ">
        <TypesMenuHeader title="Agents" />
        <div>
          <Menubar>
            <MenubarMenu>
              <MenubarTrigger>
                <PenBox className="w-4 h-4 stroke-slate-500 hover:stroke-slate-300 transition-all cursor-pointer" />
              </MenubarTrigger>
              <MenubarContent>
                <MenubarItem
                  onClick={() => {
                    router.push(`/admin/clients/tools/workflows/new`);
                    closeAgents();
                  }}
                >
                  New Shared Agent
                </MenubarItem>
                <MenubarItem
                  onClick={() => {
                    router.push(`/admin/clients/${props.client}/workflows/new`);
                    closeAgents();
                  }}
                >
                  New {toTitleCase(props.client)} Agent
                </MenubarItem>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>
      </div>
      <TypesMenuItem
        selected={!props.selectedAgentCategoryId && !props.selectedAgentTypeId}
        icon="🏠"
        title="Home"
        description="Various selection of recent agents & client agents"
        onClick={() => {
          props.onAgentCategoryChange(undefined);
          props.onAgentTypeChange(undefined);
        }}
      />
      <div className="h-9" />
      <TypesMenuHeader title="Types" onAdd={addType} />
      {types?.map((type) => (
        <TypesMenuItem
          key={type.id}
          icon={type.icon ?? ''}
          title={type.name ?? ''}
          description={type.description ?? ''}
          selected={props.selectedAgentTypeId === type.id}
          onClick={() => handleTypeClick(type.id)}
          onDelete={() => deleteType({ agent_type_id: type.id })}
          onEdit={(data) => updateType({ agent_type_id: type.id, ...data })}
        />
      ))}
      <div className="h-9" />
      <TypesMenuHeader title="Categories" onAdd={addCategory} />
      {categories?.map((category) => (
        <TypesMenuItem
          key={category.id}
          icon={category.icon ?? ''}
          title={category.name ?? ''}
          description={category.description ?? ''}
          selected={props.selectedAgentCategoryId === category.id}
          onClick={() => handleCategoryClick(category.id)}
          onDelete={() => deleteCategory({ agent_category_id: category.id })}
          onEdit={(data) => updateCategory({ agent_category_id: category.id, ...data })}
        />
      ))}
    </div>
  );
}

function TypesMenuItem(props: {
  icon: string;
  title: string;
  description: string;
  selected: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  onEdit?: (data: FormValues) => void;
}) {
  const [editMenuOpen, setEditMenuOpen] = useState(false);

  return (
    <ContextMenu>
      <ContextMenuTrigger className="relative">
        <div
          className={cn(
            'relative flex gap-4 p-4 py-5 rounded-br rounded-bl border-b items-center cursor-pointer drop-shadow-lg hover:bg-slate-900/40 transition-all',
            props.selected && 'bg-slate-900/80 hover:bg-slate-900/80',
          )}
          onClick={props.onClick}
        >
          <div className="text-2xl text-shadow">{props.icon}</div>
          <div className="flex flex-col gap-1">
            <span className="text-shadow">{props.title}</span>
            <span className="text-sm text-white/50 text-shadow">
              <Balancer>{props.description}</Balancer>
            </span>
          </div>
        </div>
        {editMenuOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black/35 backdrop-blur-sm z-[2]">
            <div className="absolute inset-0" onClick={() => setEditMenuOpen(false)} />
            <AddTypeForm
              onSubmit={(data) => {
                props.onEdit?.(data);
                setEditMenuOpen(false);
              }}
              editMode={true}
              initialValues={{
                icon: props.icon,
                name: props.title,
                description: props.description,
              }}
              className=" p-10 drop-shadow-lg rounded border border-white bg-slate-950 w-[400px] "
            />
          </div>
        )}
      </ContextMenuTrigger>
      <ContextMenuContent>
        {props.onEdit && (
          <ContextMenuItem onClick={() => setEditMenuOpen(true)}>
            <Pencil className="w-4 h-4 mr-2" />
            Edit
          </ContextMenuItem>
        )}
        {props.onDelete && (
          <ContextMenuItem onClick={props.onDelete}>
            <Trash className="w-4 h-4 mr-2" />
            Delete
          </ContextMenuItem>
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
}

type FormValues = {
  icon: string;
  name: string;
  description: string;
};

function AddTypeForm({
  onSubmit,
  className,
  editMode,
  initialValues,
}: { initialValues?: FormValues; className?: string; onSubmit: (data: FormValues) => void; editMode?: boolean }) {
  const form = useForm<FormValues>({
    defaultValues: initialValues || {
      icon: '',
      name: '',
      description: '',
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
    form.reset();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className={cn('space-y-3', className)}>
        <FormField
          control={form.control}
          name="icon"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Icon" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="name" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea placeholder="Description" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit">{editMode ? 'Update' : 'Add'}</Button>
      </form>
    </FormProvider>
  );
}

function TypesMenuHeader(props: { title: string; onAdd?: (newItem: FormValues) => void }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="p-4 py-2 flex items-center justify-between sticky top-0 backdrop-blur z-[1]">
      <span className="text-xl font-semibold ">{props.title}</span>
      {props.onAdd && (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger>
            <PlusCircle className="w-4 h-4 stroke-slate-500 cursor-pointer hover:stroke-slate-300 transition-all -mb-1" />
          </PopoverTrigger>
          <PopoverContent>
            <AddTypeForm
              onSubmit={(data) => {
                props.onAdd?.(data);
                setOpen(false);
              }}
            />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}

import { cn } from '@/lib/utils/twutils';
import { AnimatePresence, motion } from 'framer-motion';

type BFModalProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children?: React.ReactNode;
};

export default function BFModal({ isOpen, onClose, className, children }: BFModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 z-[5] bg-black/70 backdrop-blur-sm"
            transition={{
              duration: 0.3,
              ease: 'easeOut',
              exit: { duration: 0.0 },
            }}
          />

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.1, ease: 'easeOut' }}
            className={cn('relative z-[6] gap-4 border  bg-background shadow-lg sm:rounded-lg overflow-hidden', className)}
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

export function BFModalLayout(props: { children: React.ReactNode }) {
  return <div className="flex flex-col h-[95vh]">{props.children}</div>;
}

export function BFModalContent(props: { children: React.ReactNode }) {
  return <div className="w-full h-full overflow-auto flex">{props.children}</div>;
}

export function BFModalSideContent(props: { children: React.ReactNode }) {
  return <div className="w-[330px] h-full border-r shadow-slate-900/90 overflow-auto shrink-0">{props.children}</div>;
}

export function BFModalMainContent(props: { children: React.ReactNode }) {
  return <div className="w-[1100px] h-full shadow-inner bg-black/10 overflow-auto">{props.children}</div>;
}

export function BFModalTopBar(props: { children: React.ReactNode; className?: string }) {
  return <div className={cn('px-4 h-[44px] w-full border-b-white/30 border-b flex items-center justify-between', props.className)}>{props.children}</div>;
}

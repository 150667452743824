'use client';

import { Input } from '@/components/ui/input';
import { toTitleCase } from '@/lib/utils/frontend';
import { useEffect, useRef } from 'react';
import AgentRow, { type SelectedAgent } from './AgentRow';
import TypesMenu from './AgentSideMenu';
import BFModal, { BFModalContent, BFModalLayout, BFModalMainContent, BFModalSideContent } from './BFModal';
import TopBar from './DispatchTopBar';
import useLoadFlows from './useMappedFlow';

import { createContext, useContext, useState } from 'react';
import type { DispatchProps } from './DispatchTopBar';

interface AgentsContextType {
  isOpen: boolean;
  openAgents: (client: string, dispatch?: DispatchProps) => void;
  closeAgents: () => void;
  client?: string;
  dispatch?: DispatchProps;
}

const AgentsContext = createContext<AgentsContextType | undefined>(undefined);

export function AgentsProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [client, setClient] = useState<string>();
  const [dispatch, setDispatch] = useState<DispatchProps>();

  const openAgents = (clientName: string, dispatchProps?: DispatchProps) => {
    setClient(clientName);
    setDispatch(dispatchProps);
    setIsOpen(true);
  };

  const closeAgents = () => {
    setIsOpen(false);
    setClient(undefined);
    setDispatch(undefined);
  };

  return (
    <AgentsContext.Provider value={{ isOpen, openAgents, closeAgents, client, dispatch }}>
      {children}
      {client && <Agents isOpen={isOpen} onClose={closeAgents} client={client} dispatch={dispatch} />}
    </AgentsContext.Provider>
  );
}

export function useAgents() {
  const context = useContext(AgentsContext);
  if (context === undefined) {
    throw new Error('useAgents must be used within an AgentsProvider');
  }
  return context;
}

export default function Agents({
  isOpen,
  onClose,
  client,
  dispatch,
}: {
  isOpen: boolean;
  onClose: () => void;
  client: string;
  dispatch?: DispatchProps;
}) {
  const [agentCategoryId, setAgentCategoryId] = useState<number>();
  const [agentTypeId, setAgentTypeId] = useState<number>();
  const [selectedAgent, setSelectedAgent] = useState<SelectedAgent>();

  return (
    <BFModal
      isOpen={isOpen}
      onClose={() => {
        setSelectedAgent(undefined);
        onClose();
      }}
    >
      <BFModalLayout>
        {dispatch && <TopBar client={client} selectedAgent={selectedAgent} onRun={() => {}} dispatch={dispatch} />}
        <BFModalContent>
          <BFModalSideContent>
            <TypesMenu
              selectedAgentCategoryId={agentCategoryId}
              selectedAgentTypeId={agentTypeId}
              onAgentCategoryChange={setAgentCategoryId}
              onAgentTypeChange={setAgentTypeId}
              client={client}
            />
          </BFModalSideContent>
          <BFModalMainContent>
            <AgentRows
              agentCategoryId={agentCategoryId}
              agentTypeId={agentTypeId}
              client={client}
              setSelectedAgent={setSelectedAgent}
              dispatch={dispatch}
              isOpen={isOpen}
            />
          </BFModalMainContent>
        </BFModalContent>
      </BFModalLayout>
    </BFModal>
  );
}

function AgentRows(props: {
  agentCategoryId: number | undefined;
  agentTypeId: number | undefined;
  client: string;
  setSelectedAgent: (agent: SelectedAgent) => void;
  dispatch?: DispatchProps;
  isOpen: boolean;
}) {
  const [search, setSearch] = useState<string>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const hasOpenedRef = useRef(false);
  const allFlows = useLoadFlows(props.client, props.agentCategoryId, props.agentTypeId, search);

  useEffect(() => {
    if (props.isOpen && !hasOpenedRef.current) {
      searchInputRef.current?.focus();
      hasOpenedRef.current = true;
    } else if (!props.isOpen) {
      hasOpenedRef.current = false;
    }
  }, [props.isOpen]);

  return (
    <div className="relative">
      <div className="h-[48px]">
        <Input
          ref={searchInputRef}
          placeholder="Search"
          className="h-full w-full rounded-none border-none bg-none"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div>
        {allFlows.pinned.length > 0 && (
          <>
            <div className="px-10 py-3.5 text-sm font-semibold bg-slate-900 border-t sticky top-0 z-[1]">Pinned</div>
            {allFlows.pinned.map((flow) => (
              <AgentRow
                selectionModeDisabled={!props.dispatch}
                flow={flow}
                key={`${flow.flow_config_id}-${flow.extension_id}`}
                client={props.client}
                setSelectedAgent={props.setSelectedAgent}
              />
            ))}
          </>
        )}
        {allFlows.client.length > 0 && (
          <>
            <div className="px-10 py-3.5 text-sm font-semibold bg-slate-900 border-t sticky top-0 z-[1]">{toTitleCase(props.client)}</div>
            {allFlows.client.map((flow) => (
              <AgentRow
                selectionModeDisabled={!props.dispatch}
                flow={flow}
                key={`${flow.flow_config_id}-${flow.extension_id}`}
                client={props.client}
                setSelectedAgent={props.setSelectedAgent}
              />
            ))}
          </>
        )}
        {allFlows.global.length > 0 && (
          <>
            <div className="px-10 py-3.5 text-sm font-semibold bg-slate-900 border-t sticky top-0 z-[1]">Global</div>
            {allFlows.global.map((flow) => (
              <AgentRow
                selectionModeDisabled={!props.dispatch}
                flow={flow}
                key={`${flow.flow_config_id}-${flow.extension_id}`}
                client={props.client}
                setSelectedAgent={props.setSelectedAgent}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

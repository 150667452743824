import {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';

import { trpc } from '@/lib/trpc';
import { cn } from '@/lib/utils/twutils';
import { Blocks, Box, Boxes, ChevronRight, CircleAlert, Copy, Edit, ExternalLink, Workflow } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { useAgents } from './Agents';
import type { MappedFlow, MappedFlowStatus } from './useMappedFlow';

export type SelectedAgent = {
  name: string;
  subtitle?: string;
  flow_config_id: number;
  extension_id?: number;
};

export default function AgentRow(props: {
  flow: MappedFlow;
  client: string;
  setSelectedAgent: (agent: SelectedAgent) => void;
  selectionModeDisabled?: boolean;
}) {
  const { closeAgents } = useAgents();
  const { data: types } = trpc.flows.actions.listTypes.useQuery();
  const { data: categories } = trpc.flows.actions.listCategories.useQuery();
  const { mutate: setType } = trpc.flows.actions.setType.useMutation();
  const { mutate: setCategory } = trpc.flows.actions.setCategory.useMutation();
  const { mutate: setStatus } = trpc.flows.actions.setStatus.useMutation();
  const { mutate: pinFlow } = trpc.flows.actions.setPinned.useMutation();

  const agentType = useMemo(() => types?.find((type) => type.id === props.flow.agent_type_id), [types, props.flow.agent_type_id]);

  const name = props.flow.name?.replaceAll('🟢', '').replaceAll('🟡', '').replaceAll('🔴', '');

  const router = useRouter();

  const flowLink = useMemo(() => {
    return `/admin/clients/${props.flow.is_global ? 'tools' : props.client}/workflows/${props.flow.extension_id ? props.flow.extension_id : props.flow.flow_config_id}?extension=${props.flow.extension_id ? 'true' : 'false'}`;
  }, [props.flow, props.client]);

  const { mutate: createTool } = trpc.flows.extensions.create.useMutation({
    onSuccess: (data) => {
      router.push(`/admin/clients/${props.client}/workflows/${data.id}?extension=true`);
      closeAgents();
    },
  });

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div
          className="px-10 py-3.5 text-sm hover:bg-slate-900/70 transition-all cursor-pointer"
          onClick={() => {
            if (props.selectionModeDisabled) {
              router.push(flowLink);
              closeAgents();
            } else {
              props.setSelectedAgent({
                name: name || '',
                subtitle: props.flow.subtitle,
                flow_config_id: props.flow.flow_config_id,
                extension_id: props.flow.extension_id ?? undefined,
              });
            }
          }}
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <StatusCircle status={props.flow.status || 'draft'} />
              <div className="font-semibold ml-2.5 mr-1.5">{name || `Testing flow name ${(Math.random() * 100).toFixed(0)}`}</div>
              {props.flow.subtitle && (
                <div className="font-semibold text-xs text-gray-500 flex items-center">
                  <ChevronRight className="w-3 h-3 text-gray-500 mr-1" />
                  {props.flow.subtitle}
                </div>
              )}
            </div>
            <div className="flex gap-2">
              {agentType?.icon && agentType?.name && <RowLabel icon={agentType.icon} title={agentType.name} />}
              {props.flow.agent_category_ids?.map(
                (id) =>
                  categories?.find((c) => c.id === id)?.icon &&
                  categories?.find((c) => c.id === id)?.name && (
                    <RowLabel key={id} icon={categories?.find((c) => c.id === id)?.icon ?? ''} title={categories?.find((c) => c.id === id)?.name ?? ''} />
                  ),
              )}
            </div>
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="w-56">
        <ContextMenuGroup>
          <ContextMenuSub>
            <ContextMenuSubTrigger>
              <Box className="w-4 h-4 mr-2" />
              Type
            </ContextMenuSubTrigger>
            <ContextMenuSubContent className="w-48">
              {types?.map((type) => (
                <ContextMenuItem key={type.id} onClick={() => setType({ flow_config_id: Number(props.flow.flow_config_id!), agent_type_id: type.id })}>
                  <ContextMenuCheckboxItem checked={props.flow.agent_type_id === type.id} />
                  {type.icon} {type.name}
                </ContextMenuItem>
              ))}
            </ContextMenuSubContent>
          </ContextMenuSub>
          <ContextMenuSub>
            <ContextMenuSubTrigger>
              <CircleAlert className="w-4 h-4 mr-2" />
              Status
            </ContextMenuSubTrigger>
            <ContextMenuSubContent className="w-48">
              {['active', 'in-progress', 'draft', 'dead'].map((status) => (
                <ContextMenuItem key={status} onClick={() => setStatus({ flow_config_id: Number(props.flow.flow_config_id!), status: status as any })}>
                  <ContextMenuCheckboxItem checked={props.flow.status === status} />
                  <StatusCircle status={status as MappedFlowStatus} />
                  <span className="pl-2">{status}</span>
                </ContextMenuItem>
              ))}
            </ContextMenuSubContent>
          </ContextMenuSub>
          <ContextMenuSub>
            <ContextMenuSubTrigger>
              <Boxes className="w-4 h-4 mr-2" />
              Category
            </ContextMenuSubTrigger>
            <ContextMenuSubContent className="w-48">
              {categories?.map((category) => (
                <ContextMenuItem
                  key={category.id}
                  onClick={() => setCategory({ flow_config_id: Number(props.flow.flow_config_id!), agent_category_id: category.id })}
                >
                  <ContextMenuCheckboxItem checked={props.flow.agent_category_ids?.includes(category.id)} />
                  {category.icon} {category.name}
                </ContextMenuItem>
              ))}
            </ContextMenuSubContent>
          </ContextMenuSub>
          <ContextMenuSub>
            <ContextMenuItem onClick={() => pinFlow({ client: props.client, flow_config_id: Number(props.flow.flow_config_id!) })}>
              {props.flow.pinned && <ContextMenuCheckboxItem checked={true} />}
              {props.flow.pinned ? 'Unpin' : 'Pin'}
            </ContextMenuItem>
          </ContextMenuSub>
        </ContextMenuGroup>
        <ContextMenuSeparator />
        <ContextMenuGroup>
          <ContextMenuItem
            onClick={() => {
              router.push(flowLink);
              closeAgents();
            }}
          >
            <Edit className="w-4 h-4 mr-2" />
            Edit
          </ContextMenuItem>
          <ContextMenuItem onClick={() => window.open(flowLink, '_blank')}>
            <ExternalLink className="w-4 h-4 mr-2" />
            Edit in new tab
          </ContextMenuItem>
          {props.flow.is_global && !props.flow.extension_id && (
            <ContextMenuItem onClick={() => createTool({ client: props.client, flow_config_id: Number(props.flow.flow_config_id!) })}>
              <Blocks className="w-4 h-4 mr-2" />
              Extend
            </ContextMenuItem>
          )}
        </ContextMenuGroup>
        <ContextMenuSeparator />
        <ContextMenuGroup>
          <ContextMenuItem onClick={() => navigator.clipboard.writeText(`${window.location.origin}${flowLink}`)}>
            <Copy className="w-4 h-4 mr-2" />
            Copy link
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() =>
              navigator.clipboard.writeText(
                JSON.stringify(
                  {
                    name: props.flow.name,
                    event: 'Flow',
                    flowType: props.flow.type,
                    configId: props.flow.flow_config_id,
                    ...(props.flow.extension_id && { extensionId: props.flow.extension_id }),
                    refreshCadenceDays: 30,
                    mainField: '',
                  },
                  null,
                  2,
                ),
              )
            }
          >
            <Workflow className="w-4 h-4 mr-2" />
            Copy for n8n
          </ContextMenuItem>
        </ContextMenuGroup>
      </ContextMenuContent>
    </ContextMenu>
  );
}

function StatusCircle(props: { status: MappedFlowStatus }) {
  return (
    <div
      className={cn(
        'w-4 h-4 rounded-full border-2 relative',
        props.status === 'active' && 'border-green-500',
        props.status === 'in-progress' && 'border-yellow-500',
        props.status === 'draft' && 'border-gray-500 border-dotted',
        props.status === 'dead' && 'border-red-500',
      )}
    >
      {props.status === 'in-progress' && (
        <div
          className="absolute inset-0 rounded-full"
          style={{
            background: `conic-gradient(rgb(234 179 8) 60%, transparent 60%)`,
            margin: '2px',
          }}
        />
      )}
      {props.status === 'active' && (
        <div
          className="absolute inset-0 rounded-full"
          style={{
            background: 'rgb(34 197 94)', // green-500 color
            margin: '2px',
          }}
        />
      )}
    </div>
  );
}

function RowLabel(props: { icon: string; title: string }) {
  return (
    <div className="border rounded-full px-2 py-1 text-xs flex gap-1 items-center text-gray-400 bg-slate-950">
      <span className="text-[10px]">{props.icon}</span>
      <span>{props.title}</span>
    </div>
  );
}

import useProgressTracking from '@/components/hooks/useProgressTracking';
import { Button } from '@/components/ui/button';
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSub, MenubarSubContent, MenubarSubTrigger, MenubarTrigger } from '@/components/ui/menubar';
import { useToast } from '@/components/ui/use-toast';
import { trpc } from '@/lib/trpc';
import { cn } from '@/lib/utils/twutils';
import { ChevronRight, Settings } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Filter } from '../../clients/[client]/accounts/filters/schema';
import type { SelectedAgent } from './AgentRow';
import { BFModalTopBar } from './BFModal';

export type DispatchProps = {
  count: number;
  filter: Filter;
  priority?: number;
};

const ExpensiveModelsThatNeedWarning = new Set(['gpt-4o', 'o3-mini']);

export default function TopBar(props: {
  client: string;
  selectedAgent?: SelectedAgent;
  onRun: () => void;
  dispatch?: DispatchProps;
}) {
  const { toast } = useToast();
  const dispatch = props.dispatch;

  const [progressId, setProgressId] = useState<string>();
  const progress = useProgressTracking(progressId);
  const [state, setState] = useState<'waiting' | 'successed' | 'error'>('waiting');

  const { mutate, isPending: isDispatchingFlow } = trpc.dispatchFlow.useMutation({
    onMutate: () => {},
    onSuccess: ({ count }) => {
      toast({
        title: `Dispatched ${count} flows`,
      });
      setState('successed');
      setProgressId(undefined);
    },
    onError: (error) => {
      toast({
        title: 'Failed to dispatch flows',
        description: error.message,
      });
      setState('error');
      setProgressId(undefined);
    },
  });

  const { data: globalFlows } = trpc.flows.configs.list.useQuery({ client: 'tools' });
  const { data: clientFlows } = trpc.flows.configs.list.useQuery({ client: props.client });
  const classifyConfigId = useMemo(() => {
    const allFlows = [...(globalFlows || []), ...(clientFlows || [])];
    const flow = allFlows.find((flow) => flow.id === props.selectedAgent?.flow_config_id);
    return flow?.configs.classify?.id as number | undefined;
  }, [globalFlows, clientFlows, props.selectedAgent?.flow_config_id]);
  const trpcUtils = trpc.useUtils();
  trpc.configsNew.get.useQuery({ configType: 'classify', config_id: classifyConfigId! }, { enabled: !!classifyConfigId });

  useEffect(() => {
    setState('waiting');
  }, [props.selectedAgent?.flow_config_id]);

  const [priority, setPriority] = useState<number | undefined>(dispatch?.priority);

  const { mutate: createProgressTracker, isPending: isCreatingProgressTracker } = trpc.createProgressTracker.useMutation({
    onSuccess: ({ id }) => {
      if (!props.selectedAgent?.flow_config_id || !dispatch) return;
      setProgressId(id);
      mutate({
        client: props.client,
        filter: dispatch.filter,
        configId: props.selectedAgent.flow_config_id,
        extensionId: props.selectedAgent.extension_id,
        ...(priority && { priority }),
        progressId: id,
      });
    },
  });
  const handleRun = useCallback(async () => {
    if (!props.selectedAgent) {
      return;
    }

    if (classifyConfigId) {
      const classifyConfig = await trpcUtils.configsNew.get.ensureData({ configType: 'classify', config_id: classifyConfigId });
      const model = classifyConfig?.language_model;
      if (ExpensiveModelsThatNeedWarning.has(model)) {
        const confirm = window.confirm(`You're about to run an agent that uses ${model}. Are you 100% sure?`);
        if (!confirm) {
          return;
        }
      }
    }

    createProgressTracker({
      name: `${props.client} > Dispatching agent ${props.selectedAgent?.name}`,
      total: dispatch?.count,
    });
  }, [props.client, props.selectedAgent, classifyConfigId, trpcUtils.configsNew.get.ensureData, createProgressTracker, dispatch?.count]);

  const isPending = isDispatchingFlow || isCreatingProgressTracker;

  const buttonText = useMemo(() => {
    if (isPending) return 'Dispatching...';
    if (state === 'waiting') return 'Run';
    if (state === 'successed') return 'Successed';
    if (state === 'error') return 'Retry';
  }, [state, isPending]);

  return (
    <BFModalTopBar className={cn('bg-blue-800 relative', state === 'successed' && 'bg-green-600', state === 'error' && 'bg-red-600')}>
      {progress && (
        <div
          className="absolute inset-0 bg-yellow-300/20 transition-transform duration-1000 ease-out"
          style={{ transform: `translateX(-${100 - progress.percent}%)` }}
        />
      )}
      <div className="flex items-center gap-1 relative z-[1]">
        <span className="">Run on {dispatch?.count?.toLocaleString()} accounts</span>
        <ChevronRight className="w-4 h-4 stroke-white/80 mt-0.5" />
        {props.selectedAgent?.subtitle && (
          <>
            <span className={cn('text-white/60 text-sm', props.selectedAgent && 'text-white pt-0.5')}> {props.selectedAgent.subtitle}</span>
            <ChevronRight className="w-4 h-4 stroke-white/80 mt-0.5" />
          </>
        )}
        <span className={cn('text-white/60 text-sm', props.selectedAgent && 'text-white pt-0.5')}>
          {' '}
          {props.selectedAgent ? props.selectedAgent.name : 'select an agent'}
        </span>
        {priority ? (
          <>
            <ChevronRight className="w-4 h-4 stroke-white/80 mt-0.5" />
            <span className={cn('text-white/60 text-sm', props.selectedAgent && 'text-white pt-0.5')}> Priority: {priority}</span>
          </>
        ) : undefined}
      </div>
      <div className="flex items-center gap-0.5">
        <Menubar className="bg-transparent border-none h-[28px] ">
          <MenubarMenu>
            <MenubarTrigger className="bg-transparent border-none">
              <Settings className={cn('w-[18px] h-[18px] stroke-white', isPending && 'opacity-50 pointer-events-none')} />
            </MenubarTrigger>
            <MenubarContent className="border border-white/20" side="bottom" align="end">
              <MenubarSub>
                <MenubarSubTrigger>Priority</MenubarSubTrigger>
                <MenubarSubContent className="border border-white/20">
                  {[5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10].map((priority) => (
                    <MenubarItem key={priority} onClick={() => setPriority(priority)}>
                      P {priority}
                    </MenubarItem>
                  ))}
                </MenubarSubContent>
              </MenubarSub>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
        <Button
          disabled={!props.selectedAgent?.flow_config_id || isPending}
          className="h-[28px] py-0 my-0 px-3 border-white bg-green-600 hover:bg-green-700  border-none flex items-center gap-1"
          variant="outline"
          onClick={handleRun}
        >
          <span>{buttonText}</span>
        </Button>
      </div>
    </BFModalTopBar>
  );
}

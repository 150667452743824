import { trpc } from '@/lib/trpc';

export default function useProgressTracking(id?: string) {
  const { data } = trpc.QProgressTrack.get.useQuery({ id: id! }, { enabled: !!id, staleTime: 5000, refetchInterval: 5000 });

  if (!data) {
    return;
  }

  return {
    percent: Number(data.completion_perc.replace('%', '')),
    total: data.total,
  };
}
